<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Catalogo</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="products_page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-4">
                        <div class="widget">
                            <div class="category_sidebar">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="btn btn-danger btn-xs btn-block cursor-pointer mb-1" style="text-transform: capitalize;" @click="filtrar_productos">
                                            <i class="fa fa-search"></i>
                                            Filtrar
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-xs btn-block cursor-pointer mb-1" style="text-transform: capitalize;" @click="quitar_filtros">
                                            <i class="fa fa-close"></i>
                                            Quitar filtro
                                        </button>
                                    </div>
                                </div>
                                
                                <aside class="sidebar_widget">
                                    <div class="widget_title">
                                        <h5 class="heading-design-h5">Categorias</h5>
                                    </div>

                                    <div class="card">
                                        <div class="collapse show">
                                            <div class="card-block">
                                                <ul class="trends">
                                                    <li v-for="(val, key) in lista_categorias" :key="key">
                                                        <label class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                                                            <input v-model="$store.state.categoria_filtro" :value="val.cat_id" :id="'id_'+val.descripcion" @change="top_filter" type="checkbox" class="custom-control-input">
                                                            <span class="custom-control-indicator"></span>
                                                            <span class="custom-control-description text-truncate">{{val.descripcion}}</span>
                                                        </label>
                                                    </li>

                                                    <li v-if="lista_categorias.length>10">
                                                        <a href="/categorias"><strong>+ {{lista_categorias.length}} más </strong><i class="icofont icofont-bubble-right"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <hr>

                                <aside class="sidebar_widget">
                                    <div class="widget_title">
                                        <h5 class="heading-design-h5">Marcas</h5>
                                    </div>

                                    <div class="card">
                                        <div class="collapse show">
                                            <div class="card-block">
                                                <ul class="trends">
                                                    <li v-for="(val, key) in lista_marcas" :key="key">
                                                        <label class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                                                            <input v-model="$store.state.marca_filtro" :value="val.marc_id" :id="'id_'+val.marca" @change="top_filter" type="checkbox" class="custom-control-input">
                                                            <span class="custom-control-indicator"></span>
                                                            <span class="custom-control-description">{{val.marca}}</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <hr>
                                
                                <aside class="sidebar_widget">
                                    <div class="widget_title">
                                        <h5 class="heading-design-h5">Precios</h5>
                                    </div>
                                    
                                    <div class="card">
                                        <div class="collapse show">
                                            <div class="card-block">
                                                <ul class="trends">
                                                    <li v-for="(val, key) in rango_precios" :key="key">
                                                        <label class="custom-control custom-radio mb-2 mr-sm-2 mb-sm-0" :for="'id_'+key">
                                                            <input type="radio" class="custom-control-input" :value="val.desde+'-'+val.hasta" :id="'id_'+key" name="radio_precio" @change="top_filter" v-model="$store.state.precio_filtro">
                                                            <span class="custom-control-indicator"></span>
                                                            <span class="custom-control-description">S/ {{number_format(val.desde, 2)}} - S/ {{number_format(val.hasta, 2)}}</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <hr>

                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="btn btn-danger btn-xs btn-block cursor-pointer mb-1" style="text-transform: capitalize;" @click="filtrar_productos">
                                            <i class="fa fa-search"></i>
                                            Filtrar
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-xs btn-block cursor-pointer mb-1" style="text-transform: capitalize;" @click="quitar_filtros">
                                            <i class="fa fa-close"></i>
                                            Quitar filtro
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-9 col-md-8">
                        <div class="osahan_products_top_filter row">
                            <div class="col-lg-9 col-md-9 tags-action">
                                <span class="mr-1" v-for="(v, k) in top_filter_selected" :key="k">
                                    {{v??'-'}} 
                                </span>
                            </div>

                            <div class="col-lg-3 col-md-3 sort-by-btn pull-right">
                                <div class="view-mode pull-right"></div>
                                <div class="dropdown pull-right">
                                    <button class="btn btn-danger dropdown-toggle cursor-pointer" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icofont icofont-filter"></i> Ordenar Por
                                    </button>

                                    <div class="dropdown-menu pull-right" aria-labelledby="dropdownMenuButton">
                                        <a :class="['dropdown-item', (orderItem=='oferta')?'active':'']" href="javascript:void(0);" @click="sortStore('oferta', 'desc')"><i class="fa fa-angle-right" aria-hidden="true"></i> Ofertas </a>
                                        <a :class="['dropdown-item', (orderItem=='price' && orderType=='asc')?'active':'']" href="javascript:void(0);" @click="sortStore('price', 'asc')"><i class="fa fa-angle-right" aria-hidden="true"></i> Precio: Menor a mayor </a>
                                        <a :class="['dropdown-item', (orderItem=='price' && orderType=='desc')?'active':'']" href="javascript:void(0);" @click="sortStore('price', 'desc')"><i class="fa fa-angle-right" aria-hidden="true"></i> Precio: Mayor a menor </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row products_page_list">
                            <div class="clearfix"></div>

                            <div v-for="(val, key) in ProductosTienda" :key="key" class="col-lg-3 col-md-6">
                                <div class="item">
                                    <div class="h-100">
                                        <div class="product-item">
                                            <span v-if="val.descuento_web>0" class="badge badge-primary offer-badge">{{val.descuento_web}}% OFF</span>
                                            <div class="product-item-image"> 
                                                <a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id" data-toggle="tooltip" data-placement="top" :data-original-title="val.nombre_producto">
                                                    <img class="card-img-top img-fluid" :src="val.url_imagen" alt>
                                                </a>
                                            </div>
                                            <div class="product-item-body">
                                                <div class="product-item-action">
                                                    <a v-if="modo_presentacion=='StoreOnline'" data-toggle="tooltip" data-placement="top" title class="btn btn-theme-round btn-sm mr-3" href="javascript:void(0);" @click="agregarCarrito(val, 1, false)" data-original-title="Agregar a Carrito">
                                                        <i class="icofont icofont-shopping-cart"></i>
                                                    </a>
                                                    <a data-toggle="tooltip" data-placement="top" title class="btn btn-theme-round btn-sm" :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id" data-original-title="Ver Producto">
                                                        <i class="icofont icofont-eye"></i>
                                                    </a>
                                                </div>
                                                <h4 class="card-title text-truncate"><a :href="'/producto/'+sucursal_seleccionada+'/'+val.prod_id">{{val.nombre_producto}}</a></h4>
                                                <h5>
                                                    <span v-if="val.descuento_web>0" class="product-desc-price">S/{{number_format(val.precio_venta, 2)}}</span>
                                                    <span class="ml-1 product-price">S/{{number_format(val.precio_venta_real, 2)}}</span>
                                                    <span v-if="val.descuento_web>0" class="ml-1 product-discount">{{val.descuento_web}}% Off</span>
                                                </h5>
                                            </div>

                                            <div class="product-item-footer">
                                                <div class="product-item-size"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="ProductosTienda.length<1" class="col-lg-12 mb-2">
                                <div class="card mb-2">
                                    <div class="card-body ">
                                        <h4 class="card-title text-center">Lo sentimos, no encontramos resultados para <b style="color: #a32121 ;">'MI BUSQUEDA'</b>
                                        </h4> 
                                        <p class="card-text  text-center "><small class="text-muted">Tal vez su búsqueda fue demasiado específica, intente buscar con un término más general.</small></p>  
                                    </div>
                                </div>
                            </div>
                        </div>

                        <nav aria-label="Page navigation example" v-if="total_resultado>=resultado_por_pagina" >
                            <ul class="pagination justify-content-center">
                                <li :class="['page-item', (pag<=1)?'disabled':'']">
                                    <a class="page-link" href="javascript:void(0)" @click="paginateStore(pag_anterior)"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
						        </li>
                                <span :class="['mr-4', 'ml-4']">Página {{pag}} de {{total_paginas}}</span>
                                <li :class="['page-item', (pag==total_paginas)?'disabled':'']">
                                    <a class="page-link" href="javascript:void(0)" @click="paginateStore(pag_siguiente)"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

        <Login ref="form_login" :title_modal="'¡Hola!, Para comprar, ingresa a tu cuenta'"></Login>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import Login from '@/components/Login.vue';

    export default {
        name: 'catalogo',
        computed:{
            ...mapState(['empresa', 'categorias', 'marcas', 'sucursal_seleccionada']),
        },
        components: {
            Login,
		},
        created(){
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
            this.loadObjMarca();
            this.loadObjCategoria();
        },
        mounted(){
            this.pag_anterior = parseInt((this.pag)) -  1;
            this.pag_siguiente = parseInt((this.pag)) + 1;

            if(this.$route.query && this.$route.query.filter_cat)
                this.setFiltroCategoria(this.$route.query.filter_cat.split(','));

            if(this.$route.query && this.$route.query.filter_marc)
                this.setFiltroMarca(this.$route.query.filter_marc.split(','));

            if(this.$route.query && this.$route.query.filter_price)
                this.setFiltroPrecio(this.$route.query.filter_price);

            if(this.$route.query && this.$route.query.orderItem)
                this.setFiltroOrderItem(this.$route.query.orderItem);

            if(this.$route.query && this.$route.query.orderType)
                this.setFiltroOrderType(this.$route.query.orderType);

            if(this.$route.query && this.$route.query.filter_product)
                this.setFiltroProducto(this.$route.query.filter_product);

            this.cargarProductosTienda();

            //toastr.warning('No hay stock disponible para este producto', 'Mensaje', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
            document.title = "Catalogo | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                pag: (this.$route.query.pag)?this.$route.query.pag:1,
                orderItem: (this.$route.query.orderItem)?this.$route.query.orderItem:"oferta",
                orderType: (this.$route.query.orderType)?this.$route.query.orderType:"desc",
                ProductosTienda:[],
                pag_anterior:1,
                pag_siguiente:1,
                total_paginas :1,

                total_resultado:0,
                total_productos:0,
                resultado_por_pagina:12,

                misDatos:{},
                top_filter_selected: [],
                rango_precios:[
                    {desde:"0", hasta:"50"}
                    ,{desde:"50", hasta:"100"}
                    ,{desde:"100", hasta:"250"}
                    ,{desde:"250", hasta:"500"}
                    ,{desde:"500", hasta:"1000"}
                ],
                filtro_precios:[],
                id_sucursal: this.$route.params.sucursal,
                lista_categorias:[],
                lista_marcas:[],
                modo_presentacion: process.env.VUE_APP_MODE,
            }
        },
        methods:{
            ...mapMutations(['setLoading', 'setFiltroOrderItem', 'setFiltroOrderType', 'setFiltroCategoria', 'setFiltroMarca', 'setFiltroProducto', 'setFiltroPrecio', 'setCategorias']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada', 'loadObjMarca', 'loadObjCategoria']),
            cargarProductosTienda(){
                let formData = new FormData()
                formData.append('pag', this.pag);
                formData.append('orderItem', this.orderItem);
                formData.append('orderType', this.orderType);
                formData.append('resultado_pagina', this.resultado_por_pagina);
                formData.append('filtro_producto', this.$store.state.producto_filtro);

                this.$store.state.categoria_filtro.map(function(value, key) {
                    if(value)
                        formData.append('filtro_categoria[]', value);
                });

                this.$store.state.marca_filtro.map(function(value, key) {
                if(value)
                    formData.append('filtro_marca[]', value);
                });
                formData.append('filtro_precio', this.$store.state.precio_filtro);
                formData.append('sucursal', this.$store.state.sucursal_seleccionada);

                this.setLoading(true);
                this.$http.post('tiendaVirtual/productos_tienda', formData).then((res)=>{
                    this.total_resultado    = res.data.total;
                    this.total_productos    = res.data.total_records;
                    this.total_paginas      = res.data.pag;
                    this.ProductosTienda    = res.data.Products;
                    this.lista_categorias   = res.data.category;
                    this.lista_marcas       = res.data.brand;

                    this.setCategorias(this.lista_categorias);
                    setTimeout(()=>{
                        $('[data-toggle="tooltip"]').tooltip();
                    },300);

                    setTimeout(()=>{
                        this.top_filter();
                        //this.filtrar_productos();
                        this.setLoading(false); 
                    }, 500);
                }).finally(()=>{
                    
                });
            },
            sortStore(item, order){
                this.orderItem  = item;
                this.orderType  = order;

                this.filtrar_productos();
            },
            paginateStore(pagina){
                this.pag = pagina;

                this.filtrar_productos();
            },
            top_filter(){
                let self = this;
                self.top_filter_selected = [];

                this.$store.state.categoria_filtro.map(function(value, key) {
                    self.top_filter_selected.push($("#id_"+value).val());
                });

                this.$store.state.marca_filtro.map(function(value, key) {
                    self.top_filter_selected.push($("#id_"+value).val());
                });

                if(this.$store.state.precio_filtro.length){
                    let arr_precio = this.$store.state.precio_filtro.split("-");
                    self.top_filter_selected.push("S/"+this.number_format(arr_precio[0], 2)+" - S/"+this.number_format(arr_precio[1], 2));
                }

                //this.filtrar_productos();
            },
            filtrar_productos(){
                let filter_categoria    = this.$store.state.categoria_filtro;
                let filter_marca        = this.$store.state.marca_filtro;
                let filter_producto     = this.$store.state.producto_filtro;
                let filter_price        = this.$store.state.precio_filtro;
                let filter_sucursal     = this.$store.state.sucursal_seleccionada;

                let get_str = "pag="+this.pag;
                if(filter_producto)
                    get_str+="&filter_product="+filter_producto;
                if(this.orderItem)
                    get_str+= "&orderItem="+this.orderItem;
                if(this.orderType)
                    get_str+= "&orderType="+this.orderType;
                if(filter_categoria.length)
                    get_str+= "&filter_cat="+filter_categoria;
                if(filter_marca.length)
                    get_str+= "&filter_marc="+filter_marca;
                if(filter_price.length)
                    get_str+="&filter_price="+filter_price;
                
                location.href = '/catalogo/'+filter_sucursal+'?'+get_str;
            },
            quitar_filtros(){
                this.setLoading(true);

                this.setFiltroCategoria([]);
                this.setFiltroMarca([]);
                this.setFiltroPrecio([]);
                this.setFiltroProducto([]);

                this.filtrar_productos();
            },
            agregarCarrito(producto, cantidad, reemplazar_cantidad){
                if(!this.$store.state.mi_cuenta.cli_id){
                    this.$refs.form_login.abrir_login();
                    return;
                }
                
                if(producto.stock>=cantidad && producto.stock>0){
                    this.$store.commit('agregarCarrito',{
                        producto:producto,
                        cantidad:cantidad,
                        reemplazar_cantidad: reemplazar_cantidad,
                        sucursal: this.$store.state.sucursal_seleccionada,
                        incremento: true
                    });
                }else{
                    toastr.warning('No hay stock disponible para este producto', 'Mensaje', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                }
            },
        },
    }
</script>

<style  scoped>
    .product-desc-price, .product-price{
        font-size: 11px;
    }

    .product-discount{
        font-size: 10px;
    }
</style>